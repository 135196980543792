
import React from 'react';
import { LeftChevronIcon, RightChevronIcon } from '../../../core';
import { Button } from '@ui/components/core/button';
import { MoreHorizontal } from 'lucide-react';
import cn from '@ui/utils/cn';


type SimplePaginationProps = {
  classNames?: {
    root?: string;
    buttons?: string;
  };
  currentPage: number;
  totalPages: number;
  handleNextPage: () => void;
  handlePreviousPage: () => void;
  setPage: (page: number) => void;
  simplified?: boolean;
}

const SimplePagination:React.FC<SimplePaginationProps> = (props) => {
  const { classNames, currentPage, totalPages, handleNextPage, handlePreviousPage, setPage, simplified } = props;

  const getPageNumbers = () => {
    const pages = [];
    if(totalPages <= 4) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else if(simplified) {
      if (currentPage === 1) {
        pages.push(1);
        pages.push(2);
        pages.push(3);
        pages.push(4);
      } else if(currentPage === totalPages || currentPage === totalPages - 1) {
        pages.push(totalPages - 3);
        pages.push(totalPages - 2);
        pages.push(totalPages - 1);
        pages.push(totalPages);
      } else {
        if (currentPage > 1) {
          pages.push(currentPage - 1);
        }
        pages.push(currentPage);
        if (currentPage < totalPages) {
          pages.push(currentPage + 1);
        }
        if (currentPage < totalPages - 1) {
          pages.push(currentPage + 2);
        }
      }
    } else {
      if (currentPage === 1) {
        pages.push(1);
        pages.push(2);
        pages.push(3);
      } else if(currentPage === totalPages) {
        pages.push(totalPages - 2);
        pages.push(totalPages - 1);
        pages.push(totalPages);
      } else {
        if (currentPage > 1) {
          pages.push(currentPage - 1);
        }
        pages.push(currentPage);
        if (currentPage < totalPages) {
          pages.push(currentPage + 1);
        }
      }
    }
    return pages;
  };

  return totalPages > 1 ? (
    <nav className={cn('flex items-center gap-4',classNames?.root)}>
      <Button variant='tertiary' className={cn("h-8 px-0 w-8",classNames?.buttons, currentPage === 1 && !simplified && "hidden")} onClick={handlePreviousPage} disabled={currentPage === 1}>
        <LeftChevronIcon className='w-4 h-4'/>
        <span className='sr-only'>
          Previous Button
        </span>
      </Button>


      {simplified || totalPages <= 4 ? (    
        <>
          {getPageNumbers().map((page) => (
            <Button
              variant="unstyled"
              key={page}
              onClick={() => setPage(page)}
              disabled={page === currentPage}
              className={cn("h-8 px-0 w-8", page === currentPage ? 'font-bold text-white rounded-[4px] bg-pink-9 no-underline disabled:text-white disabled:bg-pink-9 disabled:no-underline' : 'no-underline rounded-[4px] hover:underline underline-offset-2')}
            >
              {page}
            </Button>
          ))}
        </>
      ):(
        <>
          {currentPage > 3 && (
            <>
              <Button
                variant='unstyled'
                onClick={() => setPage(1)}
                disabled={1 === currentPage}
                className={cn("h-8 w-8 px-0 no-underline rounded-[4px] hover:underline underline-offset-2",classNames?.buttons)}
              >
                {1}
              </Button>
              <span>
                <MoreHorizontal className="h-4 w-4" />
              </span>
            </>
          )}

          {getPageNumbers().map((page) => (
            <Button
              variant='unstyled'
              key={page}
              onClick={() => setPage(page)}
              disabled={page === currentPage}
              className={cn("h-8 px-0 w-8",page === currentPage ? 'font-bold text-white rounded-[4px] bg-pink-9 no-underline disabled:text-white disabled:bg-pink-9 disabled:no-underline' : 'no-underline rounded-[4px] hover:underline underline-offset-2')}
            >
              {page}
            </Button>
          ))}


          {currentPage < totalPages - 1 && (
            <>
              {currentPage < totalPages - 2 && <span>
                <MoreHorizontal className="h-4 w-4" />
              </span>}
              <Button
                variant='unstyled'
                onClick={() => setPage(totalPages)}
                disabled={totalPages === currentPage}
                className="h-8 w-8 px-0 no-underline rounded-[4px] hover:underline underline-offset-2"
              >
                {totalPages}
              </Button>
            </>

          )}
        
        </>
      )}

   
      
      <Button variant='tertiary' className={cn("h-8 px-0 w-8",classNames?.buttons, currentPage === totalPages && !simplified && "hidden")}  onClick={handleNextPage} disabled={currentPage === totalPages}>
        <RightChevronIcon className='w-4 h-4'/>
        <span className='sr-only'>
          Next Button
        </span>
      </Button>
    </nav>
  ):null
};

export default SimplePagination;


