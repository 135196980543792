import { Drawer,DrawerHeader,DrawerContent, DrawerBody, DrawerClose } from '@ui/components/core/drawer';
import Toggle from '../../toggles/Toggle/Toggle';
import { useRouter } from 'next/router';
import Heading from '@ui/components/shared/typography/Heading';


type CollectionFilterDrawerProps = {
  opened: boolean;
  onClose: () => void;
  sort: string;
  sortingOptions: any[];
  setSort?: (value: string) => void;
};

const CollectionSortDrawer = ({ opened, onClose, sort, sortingOptions, setSort }: CollectionFilterDrawerProps) => {
  const router = useRouter();

  return (
    <Drawer onClose={onClose} open={opened} direction='left'>
      <DrawerContent side="left" className='w-full' overlayClassName='bg-white duration-0'>
        <DrawerHeader className='flex justify-between items-center p-4'>
          <Heading asChild><h2>Sort</h2></Heading>
          <DrawerClose onClick={onClose}/>
        </DrawerHeader>
        <DrawerBody className='px-4'>
          {sortingOptions.map((sortingOption) => (
            <div key={sortingOption.label}>
              <Toggle
                className="text-[13px] tracking-[0.39px]"
                pressed={`${sortingOption.field}=${sortingOption.direction}` === sort}
                value={`${sortingOption.field}=${sortingOption.direction}`}
                onPressedChange={() => {
                  if (setSort) {
                    return setSort(`${sortingOption.field}=${sortingOption.direction}`);
                  }
                  router.query.sort = `${sortingOption.field}=${sortingOption.direction}`;
                  router.push(router);
                  onClose();
                }}
              >
                {sortingOption.label}
              </Toggle>
            </div>
          ))}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default CollectionSortDrawer;
