import { useEffect, useState } from 'react';
import { Accordion as MantineAccordion } from '@mantine/core';
import NotificationBadge from '../../badges/NotificationBadge/NotificationBadge';
import Toggle from '../../toggles/Toggle/Toggle';
import FilterBadge from '../../badges/FilterBadge/FilterBadge';
import { getFacetLabel, getFacetsMapping } from '@ui/utils/facets';
import { Button } from "@ui/components/core/button"
import { Drawer, DrawerBody, DrawerClose, DrawerContent, DrawerFooter, DrawerHeader } from '@ui/components/core/drawer';

import { useRouter } from 'next/router';
import { Facet } from '@ui/helpers/filter';
import {
  generateFilters,
  sortFacetValues,
  updateFilters,
  getFiltersUrl,
  toggleFilter as toggleFilterHelper,
} from '@ui/helpers/filter';
import Link from 'next/link';
import cn from '@ui/utils/cn';
import Heading from '../../typography/Heading';
import { useTailwindMedia } from '@ui/hooks/useTailwindMedia';
import { DownChevronIcon } from '@ui/components/core';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@ui/components/core/accordion';

type CollectionFilterDrawerProps = {
  opened: boolean;
  onClose: () => void;
  facets: Array<Facet>;
  wishlistFilters?: Array<string>;
  setFilters?: (value: string[]) => void;
  localFilter?: boolean;
};

const getColSpan = (facetLabel: string) => {
  const lookup: Record<string, string> = {
    size: 'col-span-3',
    color: 'col-span-6',
    price: 'col-span-6',
    length: 'col-span-6',
    sleeves: 'col-span-6',
  };

  if (Object.keys(lookup).includes(facetLabel.toLowerCase())) {
    return lookup[facetLabel.toLowerCase()];
  } else {
    return 'col-span-12';
  }
};

const getFilterVariant = (facetLabel: string) => {
  if (facetLabel.toLowerCase() === 'size') {
    return 'outline';
  } else if (facetLabel.toLowerCase() === 'color') {
    return 'swatch';
  } else {
    return 'radio';
  }
};

const CollectionFilterDrawer = ({ opened, onClose, localFilter = false ,wishlistFilters, facets, setFilters }: CollectionFilterDrawerProps) => {
  const router = useRouter();
  const filters = localFilter && wishlistFilters && setFilters ? wishlistFilters : generateFilters(router);
  const [localFilters, setLocalFilters] = useState<string[]>(filters);
  const appliedFilters = localFilters.filter((filter) => !filter.startsWith('category_'));
  
  const {isLg} = useTailwindMedia();

  useEffect(() => setLocalFilters(
    localFilter && wishlistFilters ? wishlistFilters : generateFilters(router)
  ), [wishlistFilters,router]);

  const isSelected = (filterValue: string) => {
    const replacedFilter = filterValue.replace('%2F', '/')
    return localFilters.some((f) => f === replacedFilter);
  };

  const resetFilters = () => {
    setLocalFilters([]);
    localFilter && setFilters ? setFilters([]) : updateFilters(router, []);
  };

  const removeFilter = (filterValue: string) => {
    const filteredValue = filterValue.replace('%2F', '/');
    setLocalFilters(localFilters.filter((f) => f !== filteredValue));
    if(localFilter && setFilters){
      setFilters(localFilters.filter((f) => f !== filteredValue));
    } else {
      updateFilters(router, localFilters.filter((f) => f !== filteredValue));
    }
  };

  const toggleFilter = (filterValue: string) => {
    const filteredValue = filterValue.replace('%2F', '/');
    if (localFilters.some((filter) => filter === filteredValue)) {
      setLocalFilters(localFilters.filter((f) => f !== filteredValue));
      if(localFilter && setFilters){
        setFilters(localFilters.filter((f) => f !== filteredValue));
      }
    } else {
      setLocalFilters([...localFilters, filteredValue]);
      if(localFilter && setFilters){
        setFilters([...localFilters, filteredValue]);
      }
    }
  };

  const handleClose = () => {
    setLocalFilters(filters);
    onClose();
  };

  useEffect(() => {
    if (isLg) {
      onClose()
    }
  }, [isLg]);

  return (
    <Drawer onClose={handleClose} open={opened} direction='left' dismissible={filters.length > 4 ? false : true}>
      <DrawerContent side="left" className='w-full' overlayClassName='bg-white duration-0' overlayFunction={handleClose}>
        <DrawerHeader className='p-4'>
          <Heading asChild><h2>Filters</h2></Heading>
          <DrawerClose onClick={handleClose}/>
        </DrawerHeader>
        <DrawerBody className='px-4'>
          <Accordion type="multiple">
            {getFacetsMapping(facets).map((facet,index) => (
              <AccordionItem key={facet.label} value={facet.label} className={index === getFacetsMapping(facets).length - 1 ? 'border-none' : ''}>
                <AccordionTrigger className="py-[14px]" disabled={facet.values.length === 0}>
                  <NotificationBadge
                    className="size-[0.75rem] mt-[0.24rem] mr-[-0.5rem] bg-brand-pink"
                    show={facet.values.some((fv) => localFilters.includes(fv.formattedValue))}
                    animate={false}
                  >
                    <span className="text-[13px]">{getFacetLabel(facet.label)}</span>
                  </NotificationBadge>
                </AccordionTrigger>
                <AccordionContent>
                  <div className={cn('grid grid-cols-12 gap-4', facet.label.toLowerCase() !== 'size' && 'pl-4')}>
                    {sortFacetValues(facet as Facet).map((fv) => (
                      <div key={fv.formattedValue.replace('%2F', '/')} className={cn(getColSpan(facet.label))}>
                        {localFilter && setFilters ? (
                          <Toggle
                            pressed={isSelected(fv.formattedValue)}
                            onPressedChange={() => toggleFilter(fv.formattedValue)}
                            variant={getFilterVariant(facet.label)}
                            value={fv.value?.toLowerCase()}
                            component="div"
                          >
                            {fv.formattedLabel}
                          </Toggle>
                        ):(
                          <Link href={getFiltersUrl(router, toggleFilterHelper(router, fv.formattedValue))!}>
                            <Toggle
                              pressed={isSelected(fv.formattedValue)}
                              onPressedChange={() => toggleFilter(fv.formattedValue)}
                              variant={getFilterVariant(facet.label)}
                              value={fv.value?.toLowerCase()}
                              component="div"
                            >
                              {fv.formattedLabel}
                            </Toggle>
                          </Link>
                        )}
                      </div>
                    ))}
                  </div>
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </DrawerBody>
        <DrawerFooter className='p-4 pt-2'>
        {appliedFilters.length > 0 && (
          <div className="overflow-auto flex space-x-2 mb-4 -mx-4 px-4 scrollbar-none pt-10">
            {appliedFilters.map((appliedFilter) => (
              <FilterBadge key={appliedFilter} value={appliedFilter} onClose={() => removeFilter(appliedFilter)} />
            ))}
          </div>
          )}

          <div className="flex space-x-4">
            <Button
              variant="outline"
              className="w-full"
              onClick={() => {
                resetFilters();
              }}
            >
              Clear All ({localFilters.length})
            </Button>
            <Button
              className="w-full"
              onClick={() => {
                onClose();

                if (localFilter && setFilters) {
                  return setFilters(localFilters);
                } else {
                  return updateFilters(router, localFilters);
                }
              }}
            >
              APPLY
            </Button>
          </div>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default CollectionFilterDrawer;
