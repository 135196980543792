import React, { useState } from 'react';
import { FloatingPosition } from '@mantine/core/lib/Floating';
import { PopoverWidth } from '@mantine/core/lib/Popover/Popover.types';
import { DownChevronIcon, Grid, Menu } from '@ui/components/core';
import NotificationBadge from '@ui/components/shared/badges/NotificationBadge/NotificationBadge';
import Toggle from '../../toggles/Toggle/Toggle';
import { getFacetLabel } from '@ui/utils/facets';
import cn from '@ui/utils/cn';
import {
  generateFilters,
  sortFacetValues,
  toggleFilter,
  applyFilterReplaceSlash,
  getFiltersUrl,
} from '@ui/helpers/filter';
import { useRouter } from 'next/router';
import { Button } from "@ui/components/core/button"
import { Facet } from '@ui/helpers/filter';
import { useElementSize } from '@mantine/hooks';
import Link from 'next/link';

type FilterMenuProps = {
  facet: Facet;
  wishlistFilters?: Array<string>;
  width?: PopoverWidth;
  position?: FloatingPosition;
  colSpan?: number;
  setFilters?: (value: string[]) => void;
  localFilter?: boolean;
};

const DROPDOWN_OFFSET = 8;

const FilterMenu = ({ facet, width, position, colSpan,localFilter = false, wishlistFilters, setFilters }: FilterMenuProps): React.ReactElement => {
  const router = useRouter();
  const label = facet.label.toLowerCase();
  const filters = localFilter && wishlistFilters ? wishlistFilters : generateFilters(router);
  const [opened, setOpened] = useState(false);
  const { ref: targetRef, width: targetWidth } = useElementSize();

  return (
    <Menu
      disabled={facet.values.length === 0}
      opened={opened}
      onChange={setOpened}
      position={position}
      width={width}
      offset={DROPDOWN_OFFSET * -1}
      classNames={{
        dropdown: 'border border-solid border-black rounded-t-none',
        item: 'p-2 data-[hovered]:bg-white',
        itemLabel: 'overflow-hidden',
      }}
    >
      <Menu.Target>
        <div ref={targetRef} className="relative">
          <NotificationBadge
            className='w-[0.75rem] h-[0.75rem] bg-brand-pink'
            show={facet.values.some((fv) =>
              filters.some(
                (filter) =>
                  (applyFilterReplaceSlash(filter) ? filter.replaceAll('/', '%2F') : filter) === fv.formattedValue,
              ),
            )}
            animate={false}
          >
            <Button
              data-opened={opened || undefined}
              disabled={facet.values.length === 0}
              onClick={() => setOpened(!opened)}
              className={cn(
                'group flex justify-between bg-white text-base h-8 w-full text-black',
                'py-0 pr-[0.75rem] pl-[1.125rem]',
                'hover:text-black hover:bg-[#F7D6E3] data-[opened]:hover:bg-white',
              )}
            >
              <p className='capitalize font-normal text-sm'>
                {getFacetLabel(facet.label)}
              </p>
              <DownChevronIcon
                className="[&_*]:fill-current group-data-[opened]:rotate-180 transition"
                height={16}
                width={16}
              />
            </Button>
          </NotificationBadge>
          {opened && (
            <div
              style={{ '--dropdown-offset': `${DROPDOWN_OFFSET - 1}px` } as any}
              className="absolute bottom-[--dropdown-offset] inset-x-0 h-px bg-white border-x border-black z-[400]"
            />
          )}
        </div>
      </Menu.Target>
      <Menu.Dropdown miw={targetWidth}>
        <Grid w="100%" m={0} gutter="0.5rem">
          {sortFacetValues(facet).map((fv) => (
            <Grid.Col span={colSpan} key={fv.formattedValue}>
              {setFilters ? (
                <Menu.Item
                  title={fv.formattedLabel}
                  onClick={() => {
                    if (filters.some((filter) => filter === fv.formattedValue.replace('%2F', '/'))) {
                      return setFilters(filters.filter((filter) => filter !== fv.formattedValue.replace('%2F', '/')));
                    } else {
                      let newFilter = filters;
                      if (facet.label === 'Price') {
                        newFilter = filters.filter((filter) => !filter.includes('price_'));
                      }

                      return setFilters([...newFilter, fv.formattedValue.replace('%2F', '/')]);
                    }
                  }}
                >
                  <Toggle
                    className={cn('w-full [&_span]:truncate', label.toLowerCase() === 'size' && '!uppercase')}
                    component="div"
                    pressed={filters.some((filter) => filter === fv.formattedValue.replace('%2F', '/'))}
                    value={fv.value?.toLowerCase()}
                    variant={label.toLowerCase() === 'color' ? 'swatch' : 'radio'}
                  >
                    {fv.formattedLabel}
                  </Toggle>
                </Menu.Item>
              ) : (
                <Menu.Item title={fv.formattedLabel} component="div">
                  <Link href={getFiltersUrl(router, toggleFilter(router, fv.formattedValue))!} className="block w-full">
                    <Toggle
                      className={cn('w-full [&_span]:truncate', label.toLowerCase() === 'size' && '!uppercase')}
                      component="div"
                      pressed={filters.some((filter) => filter === fv.formattedValue.replace('%2F', '/'))}
                      value={fv.value?.toLowerCase()}
                      variant={label.toLowerCase() === 'color' ? 'swatch' : 'radio'}
                    >
                      {fv.formattedLabel}
                    </Toggle>
                  </Link>
                </Menu.Item>
              )}
            </Grid.Col>
          ))}
        </Grid>
      </Menu.Dropdown>
    </Menu>
  );
};

export default FilterMenu;
